import {HTTP} from '../utils/axios'

class DocumentCategoryModel extends HTTP {
  // 列表
  index(data) {
    return this.request({
        url: 'board/document_categories',
        method: "GET",
        data: data
    })
  }

  /*增加*/
  create(value) {
    return this.request({
        url: "board/document_categories",
        method: "POST",
        data: value,
    })
  }

  /*修改*/
  update(value, id) {
    return this.request({
        url: "board/document_categories/" + id,
        method: "PATCH",
        data: value,
    })
  }

  /*删除*/
  destroy(id) {
    return this.request({
        url: 'board/document_categories/' + id,
        method: "DELETE"
    })
  }

  // options
  options(data) {
    return this.request({
        url: 'board/document_categories/options',
        method: "GET",
        data: data
    })
  }
}

export {DocumentCategoryModel}