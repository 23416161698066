<template>
  <div>
    <a-row>
      <a-col :span="24">
        <a-form
          :form="form"
          :label-col="{ span: 8}"
          :wrapper-col="{ span: 16 }"
        >
          <span class="title">
            基本信息
          </span>
          <a-form-item label="用户账号">
            {{ info.user_name }}
          </a-form-item>
          <a-form-item label="用户昵称">
            {{ info.nick_name }}
          </a-form-item>
          <a-form-item label="上传时间">
            {{ formatTime(info.created_at) }}
          </a-form-item>
          <a-form-item label="状态">
            {{ info.status }}
          </a-form-item>
          <a-form-item label="上传类型">
            {{ info.record_type }}
          </a-form-item>
          <a-form-item label="名称">
            {{ info.name }}
          </a-form-item>
          <a-form-item label="文件">
            {{ info.file_name }}
          </a-form-item>
          <a-form-item label="大小">
            {{ info.file_size | bToMbGb }}
          </a-form-item>
          <a-form-item label="格式">
            {{ info.file_extension }}
          </a-form-item>
          <a-form-item label="宣传图">
            <a v-if="posterKey" @click="handlePreview">点击查看</a>
          </a-form-item>
          <a-form-item label="标签">
            {{ info.label }}
          </a-form-item>

          <div v-if="!isCanceled">
            <a-divider />

            <span class="title">
              审核
            </span>

            <a-form-item label="审核人">
              {{ info.checker }}
            </a-form-item>

            <a-form-item label="审核结果">
              <a-radio-group
                v-decorator="['status',{
                  initialValue: 'passed'
                }]"
                disabled
              >
                <a-radio value="passed">
                  通过
                </a-radio>
                <a-radio value="not_pass">
                  未通过
                </a-radio>
              </a-radio-group>
            </a-form-item>

            <div v-show="isPass">
              <a-form-item label="激励积分">
                <a-input-number
                  :min="1"
                  :max="10000000"
                  :precision="0"
                  :step="1"
                  disabled
                  style="width: 100%"
                  v-decorator="['balance', {
                    initialValue: 5,
                    rules: [
                      { required: true, message: '请输入激励积分' },
                    ]
                  }]"
                />
              </a-form-item>

              <a-form-item label="文档名称">
                <a-input
                  disabled
                  v-decorator="['name', {
                    rules: [
                      { required: true, message: '请输入文档名称' },
                      { max: 100, message: '最多100个字符' },
                    ]
                  }]"
                />
              </a-form-item>

              <a-form-item label="宣传图">
              <div>支持图片格式：PNG、JPG，10M以内，推荐图标尺寸210mm*297px</div>
                <a-upload
                  accept=".png,.jpg"
                  disabled
                  :file-list="posterFileList"
                  v-decorator="['document',{
                    rules:[
                      {validator: checkDocument}
                    ]
                  }]"
                >
                  <a-icon type="loading" v-if="posterloading" />
                    <a v-if="posterFileList.length === 0">
                      点击上传
                    </a>
                </a-upload>
              </a-form-item>

              <a-form-item label="文档授权">
                <a-radio-group
                  disabled
                  v-decorator="['is_free',{
                    initialValue: false,
                    rules: [
                      { required: true, message: '请选择文档授权' },
                    ]
                  }]"
                >
                  <a-radio :value="false">
                    收费
                  </a-radio>
                  <a-radio :value="true">
                    免费
                  </a-radio>
                </a-radio-group>
              </a-form-item>

              <a-form-item label="价格(积分)" v-if="!isFree">
                <a-input-number
                  :min="1"
                  :max="10000000"
                  :precision="0"
                  :step="1"
                  style="width: 100%"
                  disabled
                  v-decorator="['point', {
                    rules: [
                      { required: !isFree && isPass, message: '请输入价格(积分)' },
                    ]
                  }]"
                />
              </a-form-item>

              <a-form-item label="推荐">
                <a-checkbox
                  v-decorator="['is_recommend',{
                    valuePropName: 'checked',
                    initialValue: false
                  }]"
                />
              </a-form-item>

              <a-form-item label="显示">
                <a-checkbox
                  disabled
                  v-decorator="['visible',{
                    valuePropName: 'checked',
                    initialValue: true
                  }]"
                />
              </a-form-item>

              <a-form-item label="一级分类">
                <a-select
                  v-decorator="['first_category_id']"
                  placeholder="请选择一级分类"
                  showSearch
                  allow-clear
                  disabled
                  :loading="loadingFirstCateOptions"
                  @focus="fetchFirstCateOptions"
                  @change="handleFirstCateChange"
                >
                  <a-select-option
                    v-for="(item, index) in firstCateOptions"
                    :key="index"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>

              <a-form-item label="二级分类">
                <a-select
                  v-decorator="['second_category_id']"
                  placeholder="请选择二级分类"
                  allow-clear
                  showSearch
                  disabled
                  :loading="loadingSecondCateOptions"
                  @change="handleSecondCateChange"
                >
                  <a-select-option
                    v-for="(item, index) in secondCateOptions"
                    :key="index"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>

                <a-form-item label="三级分类">
                <a-select
                  v-decorator="['third_category_id']"
                  placeholder="请选择三级分类"
                  showSearch
                  allow-clear
                  disabled
                  :loading="loadingThirdCateOptions"
                >
                  <a-select-option
                    v-for="(item, index) in thirdCateOptions"
                    :key="index"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>

              <a-form-item label="标签">
                <a-textarea
                  v-decorator="['label']"
                  placeholder="请为文档打上标签，每个标签不超过5个字，标签之间用顿号隔开，便签数量不超过10个"
                  autocomplete="off"
                  disabled
                  :auto-size="{ minRows: 3, maxRows: 5 }"
                />
              </a-form-item>
            </div>
            
            <div v-show="!isPass">
              <a-form-item label="原因">
                <a-textarea
                  v-decorator="['reason']"
                  placeholder="请输入未通过原因"
                  autocomplete="off"
                  disabled
                  :auto-size="{ minRows: 3, maxRows: 5 }"
                />
              </a-form-item>
            </div>
          </div>
        </a-form>
      </a-col>
    </a-row>

    <preview-image
      :visible.sync="previewVisible"
      :image="posterKey"
    />
  </div>
</template>

<script>
import formatTime from '@/utils/formatTime'
import { DocumentCategoryModel } from "@/models/document_category.js"
const documentCategoryModel = new DocumentCategoryModel()

export default {
  name: 'ApprovalDocument',
  components: {
    PreviewImage: () => import('@/components/PreviewImage'),
  },
  props: {
    info: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'approval_document' }),
      formatTime,
      posterloading: false,
      submitting: false,
      loading: true,
      isFree: false,
      isPass: true,
      isCanceled: false,
      previewVisible: false,
      firstCateOptions: [],
      secondCateOptions: [],
      thirdCateOptions: [],
      loadingFirstCateOptions: false,
      loadingSecondCateOptions: false,
      loadingThirdCateOptions: false,
      searchingRootId: 0,
      maxPosterSize: 10 * 1024 * 1024,
      posterFileList: [],
      posterKey: '',
      posterSize: 0,
      posterType: '',
      posterName: '',
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchFirstCateOptions() {
      if (this.firstCateOptions.length === 0) {
        this.loadingFirstCateOptions = true
        const data = {
          root_id: 0,
          parent_id: 0
        }
        documentCategoryModel.options(data).then(res => {
          if (res.code === 0) {
            res.result.forEach(item => {
              this.firstCateOptions.push({
                'id': item.id,
                'name': item.name
              })
            })
          }
          this.loadingFirstCateOptions = false
        })
      }
    },

    handleFirstCateChange(firstCateId) {
      this.secondCateOptions = []
      this.thirdCateOptions = []
      this.searchingRootId = 0

      this.form.setFieldsValue({
        second_cate_id: undefined,
        third_cate_id: undefined
      })

      if (firstCateId) {
        this.searchingRootId = firstCateId
        this.fetchSecondCateOptions({ root_id: firstCateId, parent_id: firstCateId })
      }
    },

    handleSecondCateChange(secondCateId) {
      this.thirdCateOptions = []

      this.form.setFieldsValue({
        third_cate_id: undefined
      })

      if (secondCateId) {
        this.fetchThirdCateOptions({ root_id: this.searchingRootId, parent_id: secondCateId })
      }
    },

    fetchSecondCateOptions(params) {
      if (this.secondCateOptions.length === 0) {
        this.loadingSecondCateOptions = true
        const data = {
          root_id: params.root_id,
          parent_id: params.parent_id
        }
        documentCategoryModel.options(data).then(res => {
          if (res.code === 0) {
            res.result.forEach(item => {
              this.secondCateOptions.push({
                'id': item.id,
                'name': item.name
              })
            })
          }
          this.loadingSecondCateOptions = false
        })
      }
    },

    handlePreview() {
      this.previewVisible = true
    },

    fetchThirdCateOptions(params) {
      if (this.thirdCateOptions.length === 0) {
        this.loadingThirdCateOptions = true
        const data = {
          root_id: params.root_id,
          parent_id: params.parent_id
        }
        documentCategoryModel.options(data).then(res => {
          if (res.code === 0) {
            res.result.forEach(item => {
              this.thirdCateOptions.push({
                'id': item.id,
                'name': item.name
              })
            })
          }
          this.loadingThirdCateOptions = false
        })
      }
    },

    fetchData() {
      this.loading = true
      if (this.info.first_category_id > 0) {
        this.fetchFirstCateOptions()
      }

      if (this.info.second_category_id > 0) {
        this.fetchSecondCateOptions({ root_id: this.info.first_category_id, parent_id: this.info.first_category_id })
      }

      if (this.info.third_category_id > 0) {
        this.fetchThirdCateOptions({ root_id: this.info.first_category_id, parent_id: this.info.second_category_id })
      }

      this.isFree = this.info.is_free
      this.isCanceled = this.info.status_slug === 'canceled'
      this.posterKey = this.info.poster_key
      if (this.info.poster_key) {
        this.posterFileList.push({
          uid: this.info.poster_key,
          name: this.info.poster_name,
          status: 'done',
        })
      }
      this.form.setFieldsValue(this.info)

      if (this.info.status_slug === 'checking') {
        this.form.setFieldsValue({
          status: 'passed'
        })
        this.isPass = true
      } else {
        this.form.setFieldsValue({
          status: this.info.status_slug
        })
        this.isPass = this.info.status_slug === 'passed'
      }
      
      if (this.info.document_name) {
        this.form.setFieldsValue({
          name: this.info.document_name
        })
      }

      if (this.info.document_label) {
        this.form.setFieldsValue({
          label: this.info.document_label
        })
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  margin-left: 95px;
  font-weight: bold;
}

.search-button {
  margin-left: 5px;
}

.ant-form-item {
  margin-bottom: 5px;
}
</style>
